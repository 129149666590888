import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest, } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

declare var $

@Component({
  selector: 'app-exceptions',
  templateUrl: './exceptions.component.html',
  styleUrls: ['./exceptions.component.scss']
})

export class ExceptionsComponent implements OnInit {
    cols: any;
    Exceptions: any;

    constructor(public generalService: GeneralService, public fb: FormBuilder, public router: Router, public http: HttpClient) {
        this.cols = [
            { field: 'CreatedDate', header: 'Sent Date', width: '60px' },
            { field: 'Ex_ErrMsg', header: 'Exception', width: '200px' },
            // { field: 'UserName', header: 'User', width: '200px' },
        ]
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
          //  this.HomeUrl = data.Webservice;
        });
    }

    ngOnInit(): void {
        this.GetException();
  }

    GetException() {
        debugger
        var url = "api/BuddyOnWheel/GettingException";
        this.generalService.GetData(url).then((data: any) => {
            debugger
            this.Exceptions = data;

        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

        });

    }


    openAddForm() {

        debugger
        $('#modal').modal('show');
        this.Clear();
    }
    Clear() {

        //this.msg = null
        //this.Name = null

    }
    ngAfterViewInit() {
        document.getElementById('preloader').classList.add('hide');
    }

}
