<div class="sidebar-outer">
  <a class="logo d-flex align-items-center" href="javascript:void(0)" *ngIf="settings.theme.sidebarIsFixed">

    <!--<a class="logo d-flex align-items-center" routerLink="/" (click)="closeSubMenus()" *ngIf="settings.theme.sidebarIsFixed">-->
    <!--<span class="ng">BOW</span>-->
    <img src="../../../../assets/img/kk.png" style="width:50%" />
 
  </a>
    <app-vertical-menu [menuItems]="menuItems"></app-vertical-menu>
</div>
