import { Menu } from './menu.model';

export const verticalMenuItems = [
    new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),    
    new Menu(2, 'Shipper', '/Admin/Userlist', null, 'users', null, false, 0),
    new Menu(5, 'Transporters', '/Admin/Transporters', null, 'pencil', null, false, 0),
    new Menu(13, 'Drivers', '/Admin/driverlist', null, 'bell-o', null, false, 0),
    new Menu(14, 'Offers', '/Admin/offers', null, 'calendar', null, false, 0),
    new Menu(3, 'In-City Bookings', '/Admin/InCityBookings', null, 'calendar', null, false, 0),
    new Menu(4, 'Out-City Bookings', '/Admin/OutCityBookings', null, 'hand-paper-o', null, false, 0),
    new Menu(6, 'Terms&Conditions', '/Admin/TermsandConditions', null, 'key', null, false, 0),
    new Menu(22, 'Hire', '/Admin/Hire', null, 'key', null, false, 0),
  new Menu(7, 'Master Tables', '', null, 'table', null, true, 0),
   new Menu(31, 'Vehicle Master', '/Admin/Vehiclemaster', null, 'expand', null, false, 7),

    new Menu(8, 'Vehicle Types', '/Admin/VehicleTypes', null, 'ambulance', null, false, 7),
    new Menu(9, 'Vehicle Tariff', '/Admin/vehicletariff', null, 'object-group', null, false, 7),
    new Menu(16, 'Status Master', '/Admin/StatusMaster', null, 'wrench', null, false, 7),

    new Menu(11, 'Product Master', '/Admin/productmaster', null, 'font', null, false, 7),

    new Menu(12, 'User Addresstype Master', '/Admin/useraddresstypemaster', null, 'th-list', null, false, 7),
    new Menu(33, 'States', '/Admin/States', null, 'font-awesome', null, false, 7),
    new Menu(34, 'Districts', '/Admin/Districts', null, 'magic', null, false, 7),
    new Menu(15, 'Cities', '/Admin/Cities', null, 'creative-commons', null, false, 7),
 //   new Menu(25, ' Driver Wallet ', '/Admin/Wallet', null, 'server', null, false, 0),

    new Menu(9, 'Reports', '', null, 'address-card-o', null, true, 0),
  //  new Menu(16, ' Delivery Reports', '/Admin/todaydelivery', null, 'keyboard-o', null, false, 9),
    
    new Menu(17, ' Sales Reports', '/Admin/todaysales', null, 'expand', null, false, 9),
  
    new Menu(18, 'Referral Reports', '/Admin/ReferralReport', null, 'laptop', null, false, 9),
    new Menu(19, 'Driver Earnings Reports', '/Admin/commission', null, 'expand', null, false, 9),
    new Menu(20, 'Driver Wallet Report', '/Admin/driverwallet', null, 'server', null, false, 9),
    new Menu(24, 'UOM Master', '/Admin/UOMMaster', null, 'server', null, false, 7),
     new Menu(21, 'Booking OrderWise Reports ', '/Admin/bookingorderstatus', null, 'server', null, false, 9),

     new Menu(23, 'Ledger Reports', '', null, 'address-card-o', null, true, 0),
    new Menu(31, 'Shipper Reports ', '/Admin/ConsignorReport', null, 'server', null, false, 23),
     new Menu(28, ' Driver Reports', '/Admin/LedgerReport', null, 'address-card-o', null, false, 23),

  new Menu(24, 'Commmision Settings ', '/Admin/Settings', null, 'server', null, false, 0),
  new Menu(32, 'mailsettings ', '/Admin/mailsettings', null, 'server', null, false, 0),

    new Menu(27, 'Notifications', '/Admin/Notification', null, 'server', null, false, 0),
   new Menu(29, 'Banners', '/Admin/Banners', null, 'comment-o', null, false, 0),
  new Menu(30, 'Admin Commission Report', '/Admin/Admincommission', null, 'map-o', null, false, 9),
  







]

export const horizontalMenuItems = [
    new Menu(1, 'ADMIN_NAV.DASHBOARD', '/', null, 'tachometer', null, false, 0),
    new Menu(2, 'ADMIN_NAV.MEMBERSHIP', '/membership', null, 'users', null, false, 0),
    new Menu(3, 'ADMIN_NAV.UI_FEATURES', null, null, 'laptop', null, true, 0),
    new Menu(4, 'Buttons', '/ui/buttons', null, 'keyboard-o', null, false, 3),
    new Menu(5, 'Cards', '/ui/cards', null, 'address-card-o', null, false, 3),
    new Menu(6, 'Components', '/ui/components', null, 'creative-commons', null, false, 3),
    new Menu(7, 'Icons', '/ui/icons', null, 'font-awesome', null, false, 3),
    new Menu(8, 'List Group', '/ui/list-group', null, 'th-list', null, false, 3),
    new Menu(9, 'Media Objects', '/ui/media-objects', null, 'object-group', null, false, 3),
    new Menu(10, 'Tabs & Accordions', '/ui/tabs-accordions', null, 'server', null, false, 3),
    new Menu(11, 'Typography', '/ui/typography', null, 'font', null, false, 3),
    new Menu(31, 'ADMIN_NAV.TOOLS', null, null, 'wrench', null, true, 3),
    new Menu(32, 'Drag & Drop', '/tools/drag-drop', null, 'hand-paper-o', null, false, 31),
    new Menu(33, 'Resizable', '/tools/resizable', null, 'expand', null, false, 31),
    new Menu(34, 'Toaster', '/tools/toaster', null, 'bell-o', null, false, 31),
    new Menu(20, 'ADMIN_NAV.FORM_ELEMENTS', null, null, 'pencil-square-o', null, true, 0),
    new Menu(21, 'Form Controls', '/form-elements/controls', null, 'check-square-o', null, false, 20),
    new Menu(22, 'Form Layouts', '/form-elements/layouts', null, 'th-large', null, false, 20),
    new Menu(24, 'Form Wizard', '/form-elements/wizard', null, 'magic', null, false, 20),
    new Menu(25, 'Editor', '/form-elements/editor', null, 'pencil', null, false, 20),
    new Menu(26, 'ADMIN_NAV.TABLES', null, null, 'table', null, true, 20),
    new Menu(27, 'Basic Tables', '/tables/basic-tables', null, 'th', null, false, 26),
    new Menu(28, 'Dynamic Tables', null, null, 'th-large', null, true, 26),
    new Menu(30, 'NGX DataTable', '/tables/dynamic-tables/ngx', null, 'caret-right', null, false, 28),
    new Menu(40, 'ADMIN_NAV.PAGES', null, null, 'file-text-o', null, true, 0),
    new Menu(15, 'ADMIN_NAV.DYNAMIC_MENU', '/dynamic-menu', null, 'list-ul', null, false, 40),
    new Menu(43, 'LOGIN', '/login', null, 'sign-in', null, false, 40),
    new Menu(44, 'REGISTER', '/register', null, 'registered', null, false, 40),
    new Menu(45, 'ADMIN_NAV.BLANK', '/blank', null, 'file-o', null, false, 40),
    new Menu(46, 'ADMIN_NAV.ERROR', '/pagenotfound', null, 'exclamation-circle', null, false, 40),
    new Menu(47, 'Profile', null, null, 'user', null, true, 40),
    new Menu(48, 'Projects', '/profile/projects', null, 'file-o', null, false, 47),
    new Menu(49, 'User Info', '/profile/user-info', null, 'address-card-o', null, false, 47),
    new Menu(50, 'ADMIN_NAV.CALENDAR', '/calendar', null, 'calendar', null, false, 40),
    new Menu(16, 'ADMIN_NAV.MAILBOX', '/mailbox', null, 'envelope-o', null, false, 40),
    new Menu(200, 'ADMIN_NAV.EXTERNAL_LINK', null, 'http://themeseason.com', 'external-link', '_blank', false, 40),
    new Menu(66, 'ADMIN_NAV.MAPS', null, null, 'globe', null, true, 0),
    new Menu(67, 'Google Maps', '/maps/googlemaps', null, 'map-marker', null, false, 66),
    new Menu(68, 'Leaflet Maps', '/maps/leafletmaps', null, 'map-o', null, false, 66),
    new Menu(70, 'ADMIN_NAV.CHARTS', null, null, 'area-chart', null, true, 0),
    new Menu(71, 'Bar Charts', '/charts/bar', null, 'bar-chart', null, false, 70),
    new Menu(72, 'Pie Charts', '/charts/pie', null, 'pie-chart', null, false, 70),
    new Menu(73, 'Line Charts', '/charts/line', null, 'line-chart', null, false, 70),
    new Menu(74, 'Bubble Charts', '/charts/bubble', null, 'comment-o', null, false, 70)
]
