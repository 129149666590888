import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
HttpClient
@Injectable({
    providedIn: 'root'
})
export class InCityBookingsService {
    arr = [];
    loginDet: any;
    constructor(private httpClient: HttpClient) {

    }

    getWebApiUrl() {
        return this.httpClient.get('../assets/WebService.json');
    }
    getBookings(urlname) {
        debugger
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.arr = []
        this.arr.push({
            UserID: this.loginDet.UserID,
            TokenID: this.loginDet.TokenID,
        });
        var payload = new FormData();
        payload.append("Param", JSON.stringify(this.arr));
        var url = "api/BuddyOnWheel/get_Bookings";
        return this.httpClient.post(urlname + url, payload);
    }
}
